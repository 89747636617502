/* eslint-disable react/prop-types */
import React from 'react';
import Layout from '../components/Layout';
import Landing from '../sections/Landing';
import About from '../sections/About';
import Members from '../sections/Members';
import Writing from '../sections/Writing';

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Landing />
    <About />
    <Members />
    <Writing />
  </Layout>
);

export default IndexPage;
